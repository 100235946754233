<template>
    <div>
        <!-- div element v-for= treatments to patch -->
        <div v-for="treatment in treatmentsToPatch" v-bind:key="treatment.id">
            <b-row class="m-0 mb-2">
                <b-col v-if="!farmSeed"
                cols="10"
                class="text-infos bg-white d-flex align-items-start py-2 pl-2 pr-1"
                >
                    <span>{{ treatment.name }}</span>
                </b-col>
                <b-col v-if="farmSeed"
                cols="4"
                class="text-infos bg-white d-flex align-items-start py-2 pl-2 pr-1"
                >
                    <span>{{ treatment.name }}</span>
                </b-col>

                <b-col cols="6" class="text-infos bg-white d-flex align-items-start pr-2" v-if="farmSeed">
                    <NumericInput
                        ref="doseRef"
                        inputRef="input"
                        name="treatment-dose"
                        :numberOfDigits="4"
                        :value="parseFloat(treatment.dose)"
                        @input="dose => {treatment.dose = dose}"
                        :required="true"
                    />
                    <b-select
                        size="sm"
                        :options="treatment.units"
                        text-field="doseUnit_unit"
                        value-field="doseUnit"
                        :value="treatment.units[0].doseUnit"
                        class="ml-1"
                        required
                    >
                    </b-select>
                </b-col>
                <b-col cols="2" class="text-infos bg-white d-flex flex-row-reverse pr-4">
                    <button class="btn align-bottom ml-1 pr-0" @click="deleteTreatment(treatment)">
                        <img :src="icon" alt="icon delete action" />
                    </button>
                </b-col>
            </b-row>
        </div>


        <!-- Dropdown for treatment selection -->
        <Dropdown
        ref="dropdown"
        class="shape-custom-select"
        :placeholder="$t('inputStock.details.placeholder')"
        label="name"
        :options="treatmentOptions"
        @input="input => addSeedTreatment(input)"
        :clearSelection="true"
        :clearOnLoad="true"
        :clearOnSelect="true"
        />
    </div>
</template>

<script>
import Dropdown from "@/components/activity/edit/Dropdown.vue";
import { mapGetters } from "vuex";
import ProductSelectionMixin from "@/mixins/ProductSelectionMixin";
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import NumericInput from "@/components/commons/NumericInput.vue";
import delIcon from "@/assets/images/delete.svg";
import { EventBus } from "@/eventBus.js";

export default {
    mixins: [ProductSelectionMixin, DetailUpdateMixin],
    components: {
        Dropdown,
        NumericInput,
    },
    data() {
        return {
            treatmentOptions: [],
            treatmentsToPatch: [],
            icon: delIcon,
            cropType_id: null
        }
    },
    mounted(){
        // Reusing the "resetProductSelection" event to reset treatmentsToPatch and treatmentOptions
        EventBus.$on("resetProductSelection", async () => {
            this.treatmentsToPatch = [];
            this.treatmentOptions = [];
        })

        if(this.currentEntry.treatments?.length > 0) this.init();
    },
    methods: {
        /** init() function does following tasks:
         *  API call to backend to get seed treatments for the provided croptype_id
         *  calls function filterTreatmentOptions - this function loads treatmentOptions
         *  along with these tasks if the function is called in edit view(of an existing inputsStock) then treatmentsToPatch is loaded with the treatments from currentEntry
         */

        async init(){

            this.treatmentsToPatch = [];
            if(this.cropType_id != null) await this.$store.dispatch( "getSeedTreatments" , [this.cropType_id]);
            this.filterTreatmentOptions();

            if( this.currentEntry.treatments?.length > 0 ){
                // Note: Iterating through the treatmentOptions is necessary to add minValue and maxValue for each element in treatmentsToPatch
                // Iterate through treatmentOptions and push the treatment details to treatmentsToPatch for which the treatment id(from treatmentOptions) match refProduct(from currenEntry)
                for(let treatment of this.treatmentOptions) {
                    this.currentEntry.treatments.map(elm => {
                        if(elm.refProduct == treatment.id){
                            this.treatmentsToPatch.push({
                            // id, name, dose, units are pushed to treatmentsToPatch from elm(of currentEntry)
                            id: elm.refProduct,
                            name: elm.refProduct_name,
                            dose: elm.dose,
                            units: [{
                                doseUnit: elm.doseUnit != null ? elm.doseUnit : treatment.doseUnit,
                                doseUnit_unit: elm.doseUnit_unit ? elm.doseUnit_unit : treatment.doseUnit_unit,
                            }],
                            // minValue and maxValue are assigned from treatment(of treatmentOptions)
                            minValue: treatment.minValue,
                            maxValue: treatment.maxValue,
                            })
                        }
                    })
                    if(this.currentEntry.treatments.length == this.treatmentsToPatch.length) break;
                }
            }
        },
        /** called in Dropdown component @input event
         * adds the selected treatment to treatmentsToPatch
         * @param {*} treatment
         */
        addSeedTreatment(treatment){
            // Condition to make sure selected treatments do not exceed the possible options
            if( treatment != undefined && !this.treatmentsToPatch.find(elm => elm.id == treatment.id)){
                this.treatmentsToPatch.push({
                    id: treatment.id,
                    name: treatment.name,
                    dose: treatment.dose,
                    units: [{
                        doseUnit: treatment.doseUnit,
                        doseUnit_unit: treatment.doseUnit_unit,
                    }],
                    minValue: treatment.minValue,
                    maxValue: treatment.maxValue,
                })
            }
        },
        /** called when delete button is clicked
         * deletes the corresponding treatment from treatmentsToPatch
         * @param {*} treatment
         */
        deleteTreatment(treatment){
            // Delete the element from treatmentstoPatch array matching the treatment_id
            if(treatment != (null || undefined)){
                let indexToDelete = this.treatmentsToPatch.indexOf(treatment);
                if(indexToDelete > -1) this.treatmentsToPatch.splice(indexToDelete, 1);
            }
        },
        /** Called in the init function(when component is created)
         * treatmentOptions is loaded from the store state seedTreatments
         */
        filterTreatmentOptions(){
            this.treatmentOptions = [];

            this.seedTreatments.map(treatment => {
                this.treatmentOptions.push({
                    id: treatment.id,
                    name: treatment.name,
                    dose: null,
                    doseUnit: treatment.productUsages[0]?.doseUnit || null,
                    doseUnit_unit: treatment.productUsages[0]?.doseUnit_unit || null,
                    minValue: treatment.productUsages[0]?.minValue || null,
                    maxValue: treatment.productUsages[0]?.maxValue || null
                })
            })
        }
    },
    computed: {
        ...mapGetters({
            seedTreatments: "seedTreatments",
            farmSeed: "inputStock/farmSeed",
        }),
    },
    watch: {
        /**
         * Watch currenProductType and in case of a change, call the init function
         * init function - resets treatmentOptions, treatmentsToPatch and calls API to get seedTreatment list for the new croptype_id
         */
        async currentProductType(){
            if(this.currentProductType?.cropType) {
                if (this.cropType_id != this.currentProductType.cropType) {
                    this.cropType_id = this.currentProductType.cropType;
                    if(this.cropType_id != null) {
                        await this.init();
                    }
                }
            }
        },
        /**
         * watch treatmentsToPatch
         * in case of any changes, it should update in the store - currentEditedEntry
         *
         */
        treatmentsToPatch: {
            deep: true,
            handler: async function(){
                let treatmentsToPatchClone = [];
                this.treatmentsToPatch.map((treatment, index) => {
                    // set a custom Validity message for treatment dose
                    const errMessage = ( treatment.dose != null && (treatment.dose > treatment.maxValue || treatment.dose < treatment.minValue)) ? `Quantité incorrect: min = ${treatment.minValue}, max = ${treatment.maxValue}` : "";
                    if(typeof this.$refs.doseRef != "undefined" && typeof this.$refs.doseRef[index] != "undefined"){
                        this.$refs.doseRef[index].$refs.input.setCustomValidity(errMessage);
                    }
                    // push dose, doseUnit and refProduct(treatment id) to array treatmentsToPatchClone
                    treatmentsToPatchClone.push({
                        dose : this.farmSeed ? treatment.dose : null,
                        doseUnit : this.farmSeed ? treatment.units[0].doseUnit : null,
                        refProduct :  treatment.id
                    })
                })
                // update the treatments array in store(:currentEditedEntry)
                await this.$store.dispatch(this.storeEditAction, { treatments: treatmentsToPatchClone });
            }
        },
        // farmSeed is a store attribute
        farmSeed(){
            // it is necesssary to make dose in treatmentsToPatch null whenever the value of farmSeed is changed
            if(!this.farmSeed){
                this.treatmentsToPatch.map(treatment => {
                    treatment.dose = null;
                })
            }
        }
    }
};
</script>

<style lang="scss" scoped>

button {
  //   background-color: $danger-color;
  font-size: 0.8em;
  font-weight: bold;

  img {
    width: 1.5em;
  }
}

</style>
